import React, { useState } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import LazyLoad from "react-lazyload";
import { useStateValue } from "../../state";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export default function EntryMedia({ photos, updatePhotoID, className }) {
  const [activeStep, setActiveStep] = useState(0);
  const [{ auth }] = useStateValue();

  if (updatePhotoID) {
    updatePhotoID(photos[activeStep]);
  }

  function handleStepChange(step) {
    setActiveStep(step);
    if (updatePhotoID) {
      updatePhotoID(photos[step]);
    }
  }

  const handleSwitching = (id, type) => {
    const photoid = photos[id];
    if (updatePhotoID && photoid) {
      updatePhotoID(photoid);
    }
  };
  return (
    <div>
      {photos && photos.length !== 0 ? (
        <LazyLoad height={300}>
          <AutoPlaySwipeableViews
            axis="x"
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            onSwitching={handleSwitching}
          >
            {photos.map((photo, index) => (
              <div key={photo}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <CardMedia
                    className={className}
                    component="img"
                    image={IMAGE_URL + "/" + photo + "?token=" + auth.token}
                    title="Photo"
                    key={photo}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </LazyLoad>
      ) : null}
    </div>
  );
}
