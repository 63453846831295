import React, { useEffect } from "react";
import "./index.css";
import withRoot from "../../withRoot";
import { makeStyles } from "@material-ui/styles";
import { Query } from "react-apollo";
import Typography from "@material-ui/core/Typography/Typography";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Card from "@material-ui/core/Card/Card";

import Entry from "../../components/entry";
import Page from "../../components/page";
import Loader from "../../components/loader";

import FilterEditor from "../../components/filterEditor";
import { ENTRY_SUBSCRIPTION, GET_ALL_ENTRIES } from "../../graphqlRequests";
import { useStateValue } from "../../state";

const styles = makeStyles(theme => ({
  root: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    [theme.breakpoints.up(764 + theme.spacing(3) * 2)]: {
      width: 764,
      marginTop: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  media: {
    top: theme.spacing(0),
    right: theme.spacing(0),
    height: 300,
    width: "auto"
  }
}));

function Error(props) {
  let { error, classes } = props;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography color="error" variant="h5" component="h2">
          There was an error retrieving entries
        </Typography>
        <Typography component="p">
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </Typography>
      </CardContent>
    </Card>
  );
}

function Loading(props) {
  let { classes } = props;
  return (
    <Card className={classes.card}>
      <CardContent className={classes.media}>
        <Typography>Loading...</Typography>
        <Loader />
      </CardContent>
    </Card>
  );
}

const sortFunction = function(a, b) {
  if (a.eventTime < b.eventTime) return +1;
  if (a.eventTime > b.eventTime) return -1;
  return 0;
};

function Entries(props) {
  const { filter, data } = props;

  useEffect(() => {
    if (process.env.REACT_APP_USE_WEB_SOCKET) {
      this.props.subscribeToNewEntries();
    }
  });

  const filteredEntries = filter(data.getEntries);

  return (
    <div>
      {filteredEntries ? (
        filteredEntries
          .sort(sortFunction)
          .map(entry => <Entry key={entry.id} entry={entry} />)
      ) : (
        <p>No entries yet.</p>
      )}
    </div>
  );
}

const Feed = ({ classes, auth, filter }) => (
  <Query query={GET_ALL_ENTRIES} errorPolicy="all">
    {({ subscribeToMore, error, loading, ...result }) => {
      if (error) return <Error error={error} classes={classes} />;
      if (loading) return <Loading classes={classes} />;
      return (
        <Entries
          {...result}
          filter={filter}
          subscribeToNewEntries={() =>
            subscribeToMore({
              document: ENTRY_SUBSCRIPTION,
              variables: {
                id: auth.sessionId,
                jwt: auth.token
              },
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newFeedItem = subscriptionData.data.entryChanges;
                if (newFeedItem.name === "") {
                  // Entry deleted
                  return Object.assign({}, prev, {
                    getEntries: prev.getEntries.filter(
                      entry => entry.id !== newFeedItem.id
                    )
                  });
                }
                return Object.assign({}, prev, {
                  getEntries: prev.getEntries
                    ? [
                        newFeedItem,
                        ...prev.getEntries.filter(
                          entry => entry.id !== newFeedItem.id
                        )
                      ] // Add or update entry
                    : [newFeedItem] // Add first entry
                });
              }
            })
          }
        />
      );
    }}
  </Query>
);

export function Timeline() {
  const classes = styles();
  const [{ auth }] = useStateValue();

  // TODO: consider using redux to keep the filter
  const [filter, setFilter] = React.useState({
    search: "",
    labels: [],
    anyLabel: false
  });

  const updateFilter = newFilter => {
    setFilter(newFilter);
  };

  const applyFilter = (filter, entries) => {
    return entries
      ? entries
          .filter(
            entry =>
              filter.labels.length === 0 ||
              (filter.anyLabel
                ? entry.labels
                    .map(function(x) {
                      return x.toLowerCase();
                    })
                    .some(r => filter.labels.indexOf(r) !== -1)
                : filter.labels.every(
                    r =>
                      entry.labels
                        .map(function(x) {
                          return x.toLowerCase();
                        })
                        .indexOf(r) !== -1
                  ))
          )
          .filter(
            entry =>
              filter.search === "" ||
              entry.description.match(filter.search) !== null ||
              entry.name.match(filter.search) !== null
          )
      : null;
  };

  return (
    <Page title="Moments">
      <div className={classes.root}>
        <FilterEditor updateFilter={updateFilter} filter={filter} />
        <Feed
          classes={classes}
          auth={auth}
          filter={arg => {
            return applyFilter(filter, arg);
          }}
        />
      </div>
    </Page>
  );
}

export default withRoot(Timeline);
