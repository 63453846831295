import React, { useState } from "react";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import EditDialog from "../editDialog";
import Chip from "@material-ui/core/Chip";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box/Box";
import EntryMenu from "./EntryMenu";
import EntryMedia from "../entryMedia";

const styles = makeStyles(theme => ({
  card: {
    position: "relative",
    minWidth: 275,
    marginTop: theme.spacing(2),
    whiteSpace: "pre-line"
  },
  header: {
    padding: theme.spacing(1),
    paddingBottom: 0
  },
  content: {
    padding: theme.spacing(1),
    paddingBottom: 0
  },
  speedDial: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
    transform: "scale(0.7)"
  },
  media: {
    top: theme.spacing(0),
    right: theme.spacing(0),
    height: 300,
    width: "auto"
  }
}));

export default function Entry(props) {
  let classes = styles();
  let { entry } = props;

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const closeEditDialog = () => {
    setEditDialogOpen(false);
  };

  const openEditDialog = () => {
    setEditDialogOpen(true);
  };

  return (
    <Box id={"entry-" + entry.id}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          action={
            <EntryMenu
              id={entry.id}
              openDialog={openEditDialog}
              className={classes.speedDial}
            />
          }
          title={<Typography variant="h5">{entry.name}</Typography>}
          subheader={
            <div>
              <Typography variant="caption">
                {new Date(entry.eventTime).toLocaleString("sv-SE")}
              </Typography>
            </div>
          }
        />
        <EntryMedia photos={entry.photos} className={classes.media} />
        <CardContent className={classes.content}>
          <Typography variant="body2">{entry.description}</Typography>
          {entry.labels.map((label, index) => {
            return <Chip key={index} label={label} />;
          })}
        </CardContent>
      </Card>
      <EditDialog
        entry={entry}
        onCloseDialog={closeEditDialog}
        dialogOpen={editDialogOpen}
      />
    </Box>
  );
}
