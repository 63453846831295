import {Route} from "react-router-dom";
import React from "react";
import {useStateValue} from "../../state";

export default function PrivateRoute(props) {
  let {component: Component, page: Alternative, ...rest} = props;
  let  [{auth}] = useStateValue();

  return (
    <Route
      {...rest}
      render={props =>
        auth && auth.authenticated === true ? (
          <Component {...props} />
        ) : (
          <Alternative {...props} />
        )
      }
    />
  );
}
