import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Mutation from "react-apollo/Mutation";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { GET_ALL_ENTRIES, DELETE_ENTRY } from "../../graphqlRequests";

export default function EntryMenu(props) {
  const { id, className, openDialog } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onClick = event => {
    if (menuOpen) {
      editClicked();
    } else {
      openMenu();
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const openMenu = () => {
    setMenuOpen(true);
  };

  const editClicked = () => {
    setMenuOpen(false);
    openDialog();
  };

  const shareClicked = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmOpen(false);
  };

  const handleOpenConfirmDialog = () => {
    setConfirmOpen(true);
  };

  return (
    <Mutation mutation={DELETE_ENTRY}>
      {(deleteEntry, { ...data }) => (
        <div>
          <Dialog
            open={confirmOpen}
            onClose={handleCloseConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
          >
            <DialogTitle id="alert-dialog-title">{"Delete Entry"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the entry? There will be no
                turning back!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog} color="primary">
                No, keep it!
              </Button>
              <Button
                onClick={e => {
                  deleteEntry({
                    variables: {
                      id: id
                    },
                    refetchQueries: [
                      {
                        query: GET_ALL_ENTRIES
                      }
                    ]
                  });
                  handleCloseConfirmDialog();
                }}
                color="primary"
                autoFocus
              >
                I'm Sure!
              </Button>
            </DialogActions>
          </Dialog>

          <SpeedDial
            ariaLabel="SpeedDial example"
            className={className}
            icon={
              <SpeedDialIcon icon={<MoreVertIcon />} openIcon={<EditIcon />} />
            }
            onBlur={closeMenu}
            onClick={onClick}
            onClose={closeMenu}
            onFocus={openMenu}
            onMouseEnter={openMenu}
            onMouseLeave={closeMenu}
            open={menuOpen}
            direction="left"
          >
            <SpeedDialAction
              key="Share"
              icon={<ShareIcon />}
              tooltipTitle="Share"
              tooltipPlacement="top"
              onClick={shareClicked}
            />
            <SpeedDialAction
              key="Delete"
              icon={<DeleteIcon />}
              tooltipTitle="Delete"
              tooltipPlacement="top"
              onClick={handleOpenConfirmDialog}
            />
          </SpeedDial>
        </div>
      )}
    </Mutation>
  );
}
