import React, { useState } from "react";
import * as moment from "moment";
import { Mutation } from "react-apollo";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EntryDialog from "../editDialog/EntryDialog";
import { makeStyles } from "@material-ui/styles";
import { GET_ALL_ENTRIES, CREATE_ENTRY } from "../../graphqlRequests";

const styles = makeStyles(theme => ({
  fab: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  }
}));

export default function CreateEntry(props) {
  let classes = styles();

  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  return (
    <div>
      <Mutation mutation={CREATE_ENTRY}>
        {(createEntry, { ...data }) => (
          <EntryDialog
            dialogOpen={dialogOpen}
            onCloseDialog={closeDialog}
            buttonLabel="Create"
            clearWhenOpen={true}
            onSubmit={d => {
              createEntry({
                variables: {
                  name: d.name,
                  description: d.description,
                  labels: d.labels,
                  photos: d.photos,
                  eventLongitude: String(d.eventLocation.lng),
                  eventLatitude: String(d.eventLocation.lat),
                  eventTime:
                    moment(d.eventTime)
                      .utc()
                      .format("YYYY-MM-DDTHH:mm:ss") + "Z"
                },
                refetchQueries: [
                  {
                    query: GET_ALL_ENTRIES
                  }
                ]
              });
            }}
          />
        )}
      </Mutation>
      <Fab
        onClick={openDialog}
        color="secondary"
        aria-label="Add"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
