import gql from "graphql-tag";

export const DELETE_ENTRY = gql`
  mutation($id: ID!) {
    deleteEntry(id: $id) {
      id
    }
  }
`;

export const GET_ALL_ENTRIES = gql`
  {
    getEntries {
      id
      eventTime
      name
      description
      labels
      photos
      eventLongitude
      eventLatitude
    }
  }
`;

export const CREATE_ENTRY = gql`
  mutation(
    $name: String!
    $description: String!
    $labels: [String]
    $photos: [String]
    $eventTime: Time!
    $eventLongitude: String
    $eventLatitude: String
  ) {
    createEntry(
      input: {
        name: $name
        description: $description
        labels: $labels
        photos: $photos
        eventTime: $eventTime
        eventLongitude: $eventLongitude
        eventLatitude: $eventLatitude
      }
    ) {
      name
      description
      labels
      eventTime
      eventLongitude
      eventLatitude
    }
  }
`;

export const UPDATE_ENTRY = gql`
  mutation(
    $id: ID!
    $name: String!
    $description: String!
    $labels: [String]
    $photos: [String]
    $eventTime: Time!
    $eventLongitude: String
    $eventLatitude: String
  ) {
    updateEntry(
      input: {
        id: $id
        name: $name
        description: $description
        labels: $labels
        photos: $photos
        eventTime: $eventTime
        eventLongitude: $eventLongitude
        eventLatitude: $eventLatitude
      }
    ) {
      id
      name
      description
      labels
      photos
      eventTime
    }
  }
`;

export const ENTRY_SUBSCRIPTION = gql`
  subscription entryChanges($id: ID!, $jwt: String!) {
    entryChanges(input: { id: $id, jwt: $jwt }) {
      id
      name
      description
      labels
      photos
      eventTime
    }
  }
`;
