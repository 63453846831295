import styled from "styled-components";
import { ReactComponent as ImportedComponent } from "./anim_logo.svg";

const Loader = styled(ImportedComponent)`
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
`;
export default Loader;
