import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";

import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { onError } from "apollo-link-error";

import { orange } from "@material-ui/core/colors";
import { useStateValue } from "./state";

const { createUploadLink } = require("apollo-upload-client");

// A theme with custom primary and secondary color. It's optional.
const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: {
      main: "#ab47bc"
    }
  }
});

export default function withRoot(Component) {
  function WithRoot(props) {
    const [{ auth }, actions] = useStateValue();

    const httpLink = createUploadLink({
      uri: process.env.REACT_APP_MOMENTS_GRAPHQL_HTTP_URL
    });

    const wsLink = new WebSocketLink({
      uri: process.env.REACT_APP_MOMENTS_GRAPHQL_WEBSOCKET_URL,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          const token = auth.token;
          return {
            authToken: `${token}`,
            headers: {
              Authorization: token ? `Bearer ${token}` : ""
            }
          };
        },
        connectionCallback: err => {
          if (err) {
            console.log("Error Connecting to Subscriptions Server", err);
          }
        }
      }
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
      const token = auth.token;
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : ""
        }
      });

      return forward(operation);
    });

    const logoutLink = onError(({ networkError }) => {
      if (networkError && networkError.statusCode === 401) {
        actions.auth.logout();
      }
    });

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const graphqlLink = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );

    const client = new ApolloClient({
      link: ApolloLink.from([logoutLink, authMiddleware, graphqlLink]),
      cache: new InMemoryCache()
    });

    // MuiThemeProvider makes the theme available down the React tree thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        <ApolloHooksProvider client={client}>
          <ApolloProvider client={client}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Component {...props} />
          </ApolloProvider>
        </ApolloHooksProvider>
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}
