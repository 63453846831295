import React, { useState } from "react";
import "./index.css";
import withRoot from "../../withRoot";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar/Avatar";
import logo from "../../static/logo.svg";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Divider from "@material-ui/core/Divider/Divider";
import Box from "@material-ui/core/Box/Box";
import LoginButton from "../../components/loginButton";
import { useStateValue } from "../../state";
import Loader from "../../components/loader";

const styles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  loginBox: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    backgroundColor: theme.palette.common.white
  }
}));

export function Login(props) {
  const classes = styles();

  const [{ auth }] = useStateValue();
  const [form, setValues] = useState({
    email: "",
    password: ""
  });

  if (!auth.initialized || auth.authenticated) {
    return <Loader />;
  }

  // const clearFields = () => {
  //   setValues({
  //     email: "",
  //     password: ""
  //   });
  // };

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.loginBox}>
        <Avatar className={classes.avatar}>
          <img src={logo} width={40} height={40} alt="Logo" />
        </Avatar>

        <TextField
          id="email"
          label="Email"
          type="email"
          autoComplete="current-email"
          margin="normal"
          onChange={updateField}
          fullWidth
          disabled={true}
        />

        <TextField
          id="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          margin="normal"
          onChange={updateField}
          fullWidth
          disabled={true}
        />

        <Box mt={3}>
          <Button variant="contained" size="medium" color="primary" disabled>
            Sign in
          </Button>
        </Box>

        <Divider variant="middle" />

        <Box mt={5}>
          <LoginButton />
        </Box>
      </div>
    </Container>
  );
}

export default withRoot(Login);
