import React from "react";
import "./index.css";
import withRoot from "../../withRoot";
import Page from "../../components/page";
import { makeStyles } from "@material-ui/styles";

const styles = makeStyles(theme => ({
  root: {
  }
}));

export function Profile({props, match}) {
  const classes = styles();

  return (
    <Page title="Moments - Profile">
      <div className={classes.root}>
        <h3>Profile: {match.params.id}</h3>
      </div>
    </Page>
  );
}

export default withRoot(Profile);
