export const initialUserState = {
  email: "",
  name: "",
  givenName: "",
  familyName: "",
  picture: "",
  wsUri: ""
};

export const userActions = dispatch => ({
  changeName: name => {
    dispatch({
      type: "changeName",
      name
    });
  },
  updateUser: user =>
    dispatch({
      type: "updateUser",
      name: user.name,
      email: user.email,
      givenName: user.givenName,
      familyName: user.familyName,
      picture: user.picture,
      wsUri: user.wsUri
    }),
  clearUser: () =>
    dispatch({
      type: "clearUser",
    })
});

const userReducer = (state, action) => {
  switch (action.type) {
    case "changeName":
      return {
        ...state,
        name: action.name
      };
    case "updateUser":
      return {
        ...state,
        name: action.name,
        email: action.email,
        givenName: action.givenName,
        familyName: action.familyName,
        picture: action.picture,
        wsUri: action.wsUri
      };
    case "clearUser":
      return {
        ...state,
        name: "",
        email: "",
        givenName: "",
        familyName: "",
        picture: "",
        wsUri: ""
      };

    default:
      return state;
  }
};

export default userReducer;
