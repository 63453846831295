import { useQuery } from "@apollo/react-hooks";
import React from "react";
import Chip from "@material-ui/core/Chip";
import { GET_ALL_ENTRIES } from "../../graphqlRequests";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField/TextField";

function FilterLabelEditor(props) {
  const { classes, updateLabels, filter, toggleAnyLabel } = props;

  const [labels, setLabels] = React.useState([]);
  const [labelFilter, setLabelFilter] = React.useState("");
  const handleCheckLabel = labelToAdd => () => {
    const result = labels.includes(labelToAdd)
      ? labels.filter(label => label !== labelToAdd)
      : [...labels, labelToAdd];
    updateLabels(result);
    setLabels(result);
  };

  const { error, loading, data } = useQuery(GET_ALL_ENTRIES);

  if (error) return "Error loading labels...";
  if (loading) return "Loading labels....";
  const allLabels = data.getEntries
    ? [
        ...new Set(
          data.getEntries
            .map(entry => {
              return filter.anyLabel
                ? entry.labels.map(function(x) {
                    return x.toLowerCase();
                  })
                : filter.labels.every(
                    r =>
                      entry.labels
                        .map(function(x) {
                          return x.toLowerCase();
                        })
                        .indexOf(r) !== -1
                  )
                ? entry.labels.map(function(x) {
                    return x.toLowerCase();
                  })
                : [];
            })
            .flat()
            .filter(label => labelFilter === "" || label.match(labelFilter))
            .sort()
        )
      ]
    : [];

  const updateLabelFilter = event => {
    setLabelFilter(event.target.value.toLowerCase());
  };

  return (
    <div className={classes.root}>
      <div>
        <FormControlLabel
          control={
            <Switch checked={!filter.anyLabel} onChange={toggleAnyLabel} />
          }
          label={filter.anyLabel ? "Any of" : "All of"}
        />

        <TextField
          className={classes.searchField}
          id="filterLabels"
          label="FilterLabels..."
          type="search"
          margin="normal"
          onChange={updateLabelFilter}
          value={labelFilter}
          fullWidth
        />
      </div>
      <div>
        {allLabels.map((label, idx) => {
          return (
            <Chip
              //size="small"
              clickable
              color={labels.includes(label) ? "primary" : "default"}
              key={idx}
              label={label}
              onClick={handleCheckLabel(label)}
              className={classes.chip}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FilterLabelEditor;
