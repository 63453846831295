import React from "react";
import { geolocated } from "react-geolocated";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import EditLocationIcon from "@material-ui/icons/EditLocationOutlined";
import LocationPicker from "react-location-picker";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = makeStyles(theme => ({
  appBar: {
    Location: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  locationContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    backgroudn: "#345677",
    flexDirection: "row"
  },
  locationText: {
    marginLeft: theme.spacing(2)
  }
}));

function CurrentLocation(props) {
  return !props.isGeolocationAvailable ? (
    <div>Your browser does not support Geolocation</div>
  ) : !props.isGeolocationEnabled ? (
    <div>Geolocation is not enabled</div>
  ) : props.coords ? null : (
    <div>Getting the location data&hellip; </div>
  );
}

const LocatedCurrentLocation = geolocated({
  LocationOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
})(CurrentLocation);

/**
 * LocationEdit is a Dialog to pick a location from a google-aos view
 *
 * The callback sent in to recive the final location is called only when the location has changed.
 *
 * If initialLocation is not null and the value of lat is not "undefined" that Location is used as default Location.
 * Else no values are set as Location and callback to update postiion is not calle until save is done in map.
 *
 * Default behavior is to not set a location before a location is picked and saved in the locationpicker map.
 *
 */
function LocationEdit(props) {
  const classes = styles();
  var initialLocation;

  // Hacky solution to handle case where the entry has no Location

  if (!props.initialLocation) {
    // console.log("Clearing initialLocation (1): ", props.initialLocation);
    initialLocation = null;
  } else {
    if (
      props.initialLocation.lng === "undefined" ||
      !props.initialLocation.lng
    ) {
      // console.log("Clearing initialLocation (2): ", props.initialLocation);
      initialLocation = null;
    } else {
      // console.log("Using initialPosition: ", props.initialLocation);
      initialLocation = props.initialLocation;
    }
  }
  // console.log("initialLocation is: ", initialLocation);

  const [longitude, setLongitude] = React.useState(
    initialLocation ? Number(initialLocation.lng) : null
  );
  const [latitude, setLatitude] = React.useState(
    initialLocation ? Number(initialLocation.lat) : null
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  // if (initialLocation) {
  //   console.log("Set mapLocation to: ", initialLocation);
  // }
  const [mapLocation, setMapLocation] = React.useState(
    initialLocation
      ? { lng: Number(initialLocation.lng), lat: Number(initialLocation.lat) }
      : {}
  );

  const [defaultPosition, setDefaultPosition] = React.useState(
    initialLocation
      ? { lng: Number(initialLocation.lng), lat: Number(initialLocation.lat) }
      : {}
  );

  const onSuccess = pos => {
    // console.log("onSuccess");
    // If we got a Location from our parent do not set the curren Location on load
    if (initialLocation) {
      console.log("Ignoring current location");
      return;
    }
    // console.log("Updating mapLocation and defaultPosition, ", pos.coords);
    setMapLocation({ lng: pos.coords.longitude, lat: pos.coords.latitude });
    setDefaultPosition({ lng: pos.coords.longitude, lat: pos.coords.latitude });
    // Uncomment to default to using location rater than requier active action to add location.
    // setLatitude(pos.coords.latitude);
    // setLongitude(pos.coords.longitude);
    // props.updateLocation({ lng: pos.coords.longitude, lat: pos.coords.latitude })
  };

  const openLocationMap = () => {
    // console.log("openLocationMap");
    setDialogOpen(true);
  };

  const handleMapLocationChanged = ({ position, address }) => {
    // console.log("handleMapLocationChanged, ", position);
    setMapLocation(position);
  };

  const onCloseDialog = () => {
    // console.log("onCloseDialog");
    setDialogOpen(false);
  };

  // console.log("mapLocation is: ", mapLocation);
  // console.log("defaultPosition is: ", defaultPosition);
  return (
    <div>
      <LocatedCurrentLocation onSuccess={pos => onSuccess(pos)} />
      {/* TODO: Replace input fields with a map showing the location. Clicking on the map should open the location picker. */}
      <div className={classes.locationContainer}>
        <Fab
          edge="start"
          color="primary"
          onClick={openLocationMap}
          aria-label="LocationPicker"
          disabled={props.disabled}
        >
          <EditLocationIcon />
        </Fab>
        {longitude ? (
          <Typography className={classes.locationText} variant="body2">
            Location: {latitude}, {longitude}
          </Typography>
        ) : null}
      </div>
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={onCloseDialog}
        TransitionComponent={Transition}
        disableBackdropClick={true}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onCloseDialog}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Choose location
            </Typography>
            <Button
              color="inherit"
              onClick={() => {
                // console.log("Save", mapLocation);
                setLongitude(mapLocation.lng);
                setLatitude(mapLocation.lat);
                props.updateLocation(mapLocation);
                onCloseDialog();
              }}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <LocationPicker
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            defaultPosition={defaultPosition}
            onChange={handleMapLocationChanged}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LocationEdit;
