import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button/Button";
import Avatar from "@material-ui/core/Avatar/Avatar";
import CreateEntry from "../createEntry";
import { makeStyles } from "@material-ui/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useStateValue } from "../../state";

const styles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    padding: 0
  },
  avatar: {},
  appBar: {
    top: "auto",
    bottom: 0
  },
  toolbar: {
    padding: 0
  }
}));

export default function MyNavBar(props) {
  const { title } = props;
  const [{ user, auth }, actions] = useStateValue();
  const classes = styles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function logout() {
    actions.auth.logout();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  let button;
  if (auth.authenticated) {
    button = (
      <React.Fragment>
        <Button
          color="inherit"
          onClick={handleClick}
          className={classes.menuButton}
        >
          <Avatar
            alt={user.givenName}
            src={user.picture}
            className={classes.avatar}
          />
        </Button>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          keepMounted
        >
          <MenuItem component={Link} to={"/"}>
            Timeline
          </MenuItem>
          <MenuItem component={Link} to={"/profile/" + auth.email}>
            Profile
          </MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
          <MenuItem onClick={handleClose}>
            v: {process.env.REACT_APP_VERSION}
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  } else {
    button = (
      <Button component={Link} to="/" color="inherit">
        Login
      </Button>
    );
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" className={classes.grow}>
          {title}
        </Typography>
        {button}
      </Toolbar>
      <CreateEntry />
    </AppBar>
  );
}
