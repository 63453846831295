import React from "react";
import { Route, Router } from "react-router-dom";
import Timeline from "./pages/timeline";
import history from "./history";
import Login from "./pages/login";
import Profile from "./pages/profile";
import PrivateRoute from "./components/PrivateRoute";
import { StateProvider } from "./state";
import userReducer, { initialUserState, userActions } from "./state/user";
import authReducer, { authActions, initialAuthState } from "./state/auth";
import { AuthenticationProvider } from "./authenticaton/Authentication";

function Routes() {
  const wsUri = process.env.REACT_APP_WS_URI;
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URI;

  const initialState = {
    user: initialUserState,
    auth: initialAuthState
  };

  const reducer = ({ user, auth }, action) => ({
    user: userReducer(user, action),
    auth: authReducer(auth, action)
  });

  const actions = dispatch => ({
    auth: authActions(dispatch),
    user: userActions(dispatch)
  });

  return (
    <StateProvider
      initialState={initialState}
      reducer={reducer}
      actions={actions}
    >
      <AuthenticationProvider
        wsUri={wsUri}
        clientId={clientId}
        redirectUri={redirectUri}
      >
        <Router history={history}>
          <div>
            <PrivateRoute exact path="/" component={Timeline} page={Login} />
            <Route exact path="/profile/:id" component={Profile} />
          </div>
        </Router>
      </AuthenticationProvider>
    </StateProvider>
  );
}

export default Routes;
