import React, { useEffect } from "react";
import { useStateValue } from "../../state";

export default function LoginButton() {
  const [{ auth }, actions] = useStateValue();

  const onLoginSuccess = res => {
    let auth = res.getAuthResponse();
    let token = auth.id_token;
    let expiresAt = auth.expires_at;
    actions.auth.authenticate(token, expiresAt);
  };

  const onLoginFailure = response => {
    console.log(response);
  };

  useEffect(() => {
    if (auth.initialized && !auth.authenticated) {
      window.gapi.signin2.render("oauth2-login-button", {
        width: 250,
        height: 50,
        longtitle: true,
        onsuccess: res => onLoginSuccess(res),
        onfailure: res => onLoginFailure(res)
      });
    }
  });

  let content;
  if (!auth.initialized) {
    content = undefined;
  } else if (auth.authenticated) {
    content = "loggedIn";
  } else {
    content = <div id="oauth2-login-button" />;
  }

  return <>{content}</>;
}
