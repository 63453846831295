import React, { useState } from "react";
import Fab from "@material-ui/core/Fab";
import EntryMedia from "../entryMedia";
import request from "superagent";
import imageCompression from "browser-image-compression";
import Loader from "../loader/index.js";
import { makeStyles } from "@material-ui/styles";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import UploadIcon from "@material-ui/icons/BackupOutlined";

import Tooltip from "@material-ui/core/Tooltip";
import { useStateValue } from "../../state";

const styles = makeStyles(theme => ({
  imgHolder: {
    position: "relative"
  },
  IconButton: {
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  media: {
    top: theme.spacing(0),
    right: theme.spacing(0),
    height: 300,
    width: "auto"
  },
  dropZone: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    borderWidth: "2px"
  }
}));

const dzBaseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "2px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const dzActiveStyle = {
  borderColor: "#2196f3"
};

const dzAcceptStyle = {
  borderColor: "#00e676"
};

const dzRejectStyle = {
  borderColor: "#ff1744"
};

export default function PhotoEdit(props) {
  let { handleAdd, uploadStatus, initialPhotos } = props;
  const [_photos, setPhotos] = useState(initialPhotos ? initialPhotos : []);
  const [photoID, setPhotoID] = useState(initialPhotos ? initialPhotos[0] : "");
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [{ auth }] = useStateValue();

  let classes = styles();

  const onDrop = React.useCallback(
    acceptedFiles => {
      setUploadInProgress(true);
      uploadStatus(true);
      const req = request
        .post(process.env.REACT_APP_IMAGE_UPLOAD_URL)
        .set("Authorization", "Bearer " + auth.token);

      const start = async () => {
        await asyncForEach(Array.from(acceptedFiles), async file => {
          var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          };
          const compressedFile = await imageCompression(file, options);
          req.attach("File", compressedFile);
        });
        req.end((err, result) => {
          const photosByName = result.body.map(photo => {
            return photo.stored_file_name;
          });
          setUploadInProgress(false);
          uploadStatus(false);
          setPhotos([..._photos, ...photosByName]);
          handleAdd([..._photos, ...photosByName]);
        });
      };
      start();
    },
    [_photos, handleAdd, uploadStatus, auth]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: "image/jpeg" });

  const style = React.useMemo(
    () => ({
      ...dzBaseStyle,
      ...(isDragActive ? dzActiveStyle : {}),
      ...(isDragAccept ? dzAcceptStyle : {}),
      ...(isDragReject ? dzRejectStyle : {})
    }),
    [isDragAccept, isDragActive, isDragReject]
  );

  const setID = id => {
    setPhotoID(id);
  };

  const asyncForEach = async (array, callback) => {
    for (var i = 0; i < array.length; i++) {
      await callback(array[i]);
    }
  };

  return (
    <div>
      {uploadInProgress ? (
        <div className={classes.media}>
          <Loader />
        </div>
      ) : (
        <div>
          <section className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <div className={classes.dropZone}>
                  <p>Drop the files here ...</p>
                  <UploadIcon />
                </div>
              ) : (
                <div className={classes.dropZone}>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                  <UploadIcon />
                </div>
              )}
            </div>
          </section>
          <div className={classes.imgHolder}>
            <EntryMedia
              photos={_photos}
              updatePhotoID={setID}
              className={classes.media}
            />
            {_photos.length !== 0 && (
              <Tooltip title="Delete Shown Image">
                <Fab
                  edge="start"
                  color="primary"
                  aria-label="Delete Image"
                  className={classes.IconButton}
                  disabled={_photos.length === 0}
                  onClick={() => {
                    const filteredPhotos = _photos.filter(
                      photo => photo !== photoID
                    );
                    setPhotos(filteredPhotos);
                    handleAdd(filteredPhotos);
                  }}
                >
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
