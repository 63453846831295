import TextField from "@material-ui/core/TextField/TextField";
import React from "react";
import FilterLabelEditor from "./FilterLabelEditor";
import ErrorBoundary from "../errorBoundary";
import { IconButton } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import FilterIconChecked from "@material-ui/icons/SortOutlined";

import { makeStyles } from "@material-ui/styles";

const styles = makeStyles(theme => ({
  root: {
    width: "auto",
    display: "flex"
  },
  chip: {
    margin: theme.spacing(0.4)
  },
  chipArray: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  andString: {
    fontSize: "30px",
    border: "1px"
  },
  searchField: {
    marginTop: "0",
    paddingTop: "0"
  }
}));

export default function FilterCreator(props) {
  const { updateFilter, filter } = props;
  const classes = styles();
  const [expansionPanelOpen, setExpansionPanelOpen] = React.useState(false);
  const toggleExpansionPanelOpen = () => {
    setExpansionPanelOpen(!expansionPanelOpen);
  };

  const updateLabels = labels => {
    updateFilter({ ...filter, labels: labels });
  };

  const updateFilterSearch = event => {
    updateFilter({ ...filter, search: event.target.value });
  };

  const toggleAnyLabel = () => {
    updateFilter({ ...filter, anyLabel: !filter.anyLabel });
  };

  return (
    <div>
      <div className={classes.root}>
        <TextField
          className={classes.searchField}
          id="search"
          label="Search..."
          type="search"
          margin="normal"
          onChange={updateFilterSearch}
          fullWidth
        />
        <IconButton onClick={toggleExpansionPanelOpen}>
          {expansionPanelOpen ? <FilterIconChecked /> : <FilterIcon />}
        </IconButton>
      </div>
      <div>
        <ErrorBoundary>
          {expansionPanelOpen ? (
            <FilterLabelEditor
              classes={classes}
              updateLabels={updateLabels}
              filter={filter}
              toggleAnyLabel={toggleAnyLabel}
            />
          ) : null}
        </ErrorBoundary>
      </div>
    </div>
  );
}
