export const initialAuthState = {
  token: undefined,
  expiresAt: undefined,
  sessionId: undefined,
  initialized: false,
  authenticated: false
};

const wsUri = process.env.REACT_APP_WS_URI;
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const redirectUri = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URI;

function TestUser() {
  return (
    process.env.REACT_APP_TEST_USER && process.env.REACT_APP_TEST_USER !== ""
  );
}

const fetchUserInfo = token => {
  return fetch(wsUri + "/user", {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  }).then(response => {
    return response.json().then(data => {
      if (response.ok) {
        return data;
      } else {
        return Promise.reject({ status: response.status, data });
      }
    });
  });
};

const authenticate = (dispatch, token, expiresAt) => {
  if (TestUser()) {
    console.log("TEST USER " + process.env.REACT_APP_TEST_USER);
    token = "1111";
    let date = new Date();
    date.setDate(date.getDate() + 7);
    expiresAt = date.getTime() / 1000;
  }
  if (token && expiresAt) {
    fetchUserInfo(token)
      .then(data => {
        dispatch({
          type: "authenticate",
          token,
          expiresAt,
          authenticated: true
        });
        dispatch({
          type: "updateUser",
          email: data.email,
          name: data.name,
          picture: data.picture,
          locale: data.locale,
          givenName: data.given_name,
          familyName: data.family_name
        });
      })
      .catch(error => {
        console.error("Failed to fetch user info", error);
        logout(dispatch);
      });
  }
};

const logout = dispatch => {
  localStorage.clear();
  const auth2 = window.gapi.auth2.getAuthInstance();
  if (auth2) {
    auth2.signOut().then(() => {
      console.log("User signed out.");
      dispatch({
        type: "logout"
      });
      dispatch({
        type: "clearUser"
      });
    });
  }
};

const initialized = dispatch => {
  const sessionId =
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9);
  dispatch({
    type: "initialized",
    initialized: true,
    sessionId
  });
};

const initialize = dispatch => {
    window.gapi.load("auth2", () => {
      const params = {
        client_id: clientId,
        fetch_basic_profile: true,
        // ux_mode: "redirect",
        redirect_uri: redirectUri,
        scope: "profile email",
        access_type: "online"
      };
      window.gapi.auth2.init(params).then(
        res => {
          const auth = res.currentUser.get().getAuthResponse();
          if (auth) {
            let token = auth.id_token;
            let expiresAt = auth.expires_at;
            authenticate(token, expiresAt);
            initialized(dispatch);
          }
        },
        err => {
          console.log("Auth failed: ", err);
          initialized(dispatch);
        }
      );
    });
};

export const authActions = dispatch => ({
  initialized: () => initialize(dispatch),
  authenticate: (token, expiresAt) => authenticate(dispatch, token, expiresAt),
  logout: () => logout(dispatch),
  initialize: () => initialize(dispatch)
});

const authReducer = (state, action) => {
  switch (action.type) {
    case "initialized":
      return {
        ...state,
        initialized: action.initialized,
        sessionId: action.sessionId
      };
    case "authenticate":
      return {
        ...state,
        token: action.token,
        expiresAt: action.expiresAt,
        authenticated: action.authenticated
      };
    case "logout":
      return {
        ...state,
        token: undefined,
        expiresAt: undefined,
        authenticated: false
      };

    default:
      return state;
  }
};

export default authReducer;
