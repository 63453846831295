import React from "react";
import { Mutation } from "react-apollo";
import * as moment from "moment";
import EntryDialog from "./EntryDialog";
import { UPDATE_ENTRY } from "../../graphqlRequests";

export default function EditDialog(props) {
  const { entry, dialogOpen, onCloseDialog } = props;

  return (
    <Mutation mutation={UPDATE_ENTRY}>
      {(updateEntry, { ...data }) => (
        <EntryDialog
          dialogOpen={dialogOpen}
          onCloseDialog={onCloseDialog}
          entry={entry}
          buttonLabel="Update"
          clearWhenOpen={false}
          onSubmit={d => {
            updateEntry({
              variables: {
                id: d.id,
                name: d.name,
                description: d.description,
                labels: d.labels,
                photos: d.photos,
                eventLongitude: String(d.eventLocation.lng),
                eventLatitude: String(d.eventLocation.lat),
                eventTime:
                  moment(d.eventTime)
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss") + "Z"
              }
            });
            onCloseDialog();
          }}
        />
      )}
    </Mutation>
  );
}
