import React, { useEffect } from "react";
import { useStateValue } from "../state";

export function AuthenticationProvider(props) {
  const [{ auth }, actions] = useStateValue();

  // const isAuthenticated = () => {
  //   let expiresAt = auth.expiresAt;
  //   let token = localStorage.getItem("token");
  //   return token !== null && expiresAt !== null && Date.now() < expiresAt;
  // };

  // const refreshToken = () => {
  //   if (window.gapi.auth2 && window.gapi.auth2.getAuthInstance()) {
  //     window.gapi.auth2
  //       .getAuthInstance()
  //       .currentUser.get()
  //       .reloadAuthResponse()
  //       .then(
  //         res => {
  //           console.log("Auth reload");
  //           authenticate(res);
  //         },
  //         err => {
  //           console.log("Auth failed " + err);
  //         }
  //       );
  //   } else {
  //     console.log("Auth refresh failed");
  //   }
  // };

  useEffect(() => {
    if (!auth.initialized) {
      actions.auth.initialize();
    }
  });

  return <>{props.children}</>;
}
