import React from "react";
import { makeStyles } from "@material-ui/styles";

import NavBar from "../navbar";

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    paddingBottom: 80
  }
}));

export default function Page(props) {
  const { children } = props;
  const classes = styles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
      <NavBar />
    </div>
  );
}
