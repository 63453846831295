import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input/Input";
import ChipInput from "material-ui-chip-input";
import * as moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import PhotoEdit from "../photoEdit";
import LocationEdit from "../locationEdit";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

export default function EntryDialog(props) {
  let {
    dialogOpen,
    onCloseDialog,
    onSubmit,
    entry,
    buttonLabel,
    clearWhenOpen
  } = props;

  const classes = styles();
  const [form, setValues] = React.useState({
    name: entry ? entry.name : "",
    description: entry ? entry.description : "",
    labels: entry
      ? entry.labels.map(function(x) {
          return x.toLowerCase();
        })
      : [],
    photos: entry ? entry.photos : [],
    eventLocation: entry
      ? {
          lng: entry.eventLongitude,
          lat: entry.eventLatitude
        }
      : {},
    eventTime: entry
      ? moment
          .utc(entry.eventTime.substring(0, 19))
          .local()
          .format("YYYY-MM-DDTHH:mm:ss")
      : moment().format("YYYY-MM-DDTHH:mm:ss")
  });

  const clearFields = () => {
    setValues({
      name: "",
      description: "",
      labels: [],
      photos: [],
      eventLocation: {},
      eventTime: moment().format("YYYY-MM-DDTHH:mm:ss")
    });
  };

  const [disableInput, setDisableInput] = React.useState(false);

  const uploadStatus = uploading => {
    console.log("In uploadStatus");
    setDisableInput(uploading);
  };

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const updateLocation = pos => {
    setValues({
      ...form,
      eventLocation: pos
    });
  };

  const handleLabelAdd = label => {
    setValues({
      ...form,
      labels: [...form.labels, label.toLowerCase()]
    });
  };

  const handlePhotoAdd = photos => {
    setValues({
      ...form,
      photos: photos
    });
  };

  const handleLabelDelete = (value, index) => {
    if (form.labels.indexOf(value) !== -1) {
      let labels = form.labels;
      labels.splice(index, 1);
      setValues({ ...form, labels: labels });
    }
  };

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={onCloseDialog}
      onEnter={clearWhenOpen ? clearFields : () => {}}
      TransitionComponent={Transition}
      disableBackdropClick={true}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCloseDialog}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {buttonLabel}
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              onSubmit({
                id: entry ? entry.id : null,
                name: form.name,
                description: form.description,
                labels: form.labels,
                photos: form.photos,
                eventLocation: form.eventLocation,
                eventTime:
                  moment(form.eventTime)
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss") + "Z"
              });
              onCloseDialog();
            }}
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="name">Title</InputLabel>
          <Input
            id="name"
            name="name"
            onChange={updateField}
            value={form.name}
            autoFocus
            disabled={disableInput}
          />
        </FormControl>

        <PhotoEdit
          handleAdd={handlePhotoAdd}
          initialPhotos={clearWhenOpen ? [] : form.photos}
          uploadStatus={uploadStatus}
        />
        <ChipInput
          value={form.labels}
          onAdd={label => handleLabelAdd(label)}
          onDelete={(label, index) => handleLabelDelete(label, index)}
          newChipKeyCodes={[9, 13, 12]}
          fullWidth
          label="Labels"
          disabled={disableInput}
        />
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="description">Description</InputLabel>
          <Input
            name="description"
            type="text"
            multiline
            onChange={updateField}
            id="description"
            value={form.description}
            disabled={disableInput}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="eventTime">Event Time</InputLabel>
          <Input
            name="eventTime"
            type="datetime-local"
            onChange={updateField}
            id="eventTime"
            value={form.eventTime}
            disabled={disableInput}
          />
        </FormControl>
        <LocationEdit
          initialLocation={entry ? form.eventLocation : null}
          updateLocation={updateLocation}
          disabled={disableInput}
        />
      </DialogContent>
    </Dialog>
  );
}
